import { environment } from '@environments/environment';

const developmentAppRoutes = {
  DEVELOPMENT: 'dev',
  PREVIEW: 'preview',
  FEATURE_PREVIEW: 'feature-preview',
  LANDING_PAGE: 'landing-page',
  SEARCH: 'search',
  PLP: 'plp',
  PDP: 'pdp',
  PROFILE_INFO: 'profile-info',
  ACCOUNT: 'account',
  ORDER_HISTORY: 'order-history'
};

export const AppRoutes = {
  ...(environment.production ? {} : developmentAppRoutes),
  ROOT: '',
  WILD_CARD: '**'
};
